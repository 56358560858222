<template>
  <main class="AllocationDetailsView" v-if="allocation">
    <div class="container">
      <header class="d-flex mb-3">
        <h1 class="m-0">{{ allocation.name }}</h1>

        <button class="btn btn-sm btn-primary ms-auto" @click="openForm(allocation)" v-if="isAuthAdmin">
          Edit
        </button>
      </header>

      <section class="AllocationDetailsView__meta mb-3">
        <div class="row g-3">
          <div class="col-md-6 col-lg-3">
            <div class="AllocationDetailsView__meta__status card">
              <div class="card-body">
                <label>Status</label>
                <div class="AllocationDetailsView__meta__value">
                  <AllocationPosition v-if="allocation.type === 'futures'" :position="position.position" />
                  <AllocationPosition v-if="allocation.type === 'spot'" position="spot" />
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6 col-lg-3">
            <div class="AllocationDetailsView__meta__date card">
              <div class="card-body">
                <label>Start date</label>
                <div class="AllocationDetailsView__meta__value">
                  <div>{{ formatRelativeDate(allocation.created_at) }}</div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6 col-lg-3">
            <div class="AllocationDetailsView__meta__exchange card">
              <div class="card-body">
                <label>Exchange</label>
                <div class="AllocationDetailsView__meta__value">
                  <ExchangeName :exchange="allocation.exchange" />
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6 col-lg-3">
            <div class="AllocationDetailsView__meta__profit card">
              <div class="card-body">
                <label>Wallet</label>
                <div class="AllocationDetailsView__meta__value">
                  <AppValue :value="allocation.balance" before="$" :colored="false" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <!-- <section class="AllocationDetailsView__perf mb-3">
        <div class="card">
          <div class="card-header">
            <h4>Performance</h4>
          </div>
          <div class="card-body">
            <div class="AllocationDetailsView__chart">
              <div class="AllocationDetailsView__chart__values">
                <div>
                  <AppValue :value="allocation.perf.pnl" after="%" />
                  <div>{{ allocation.name }} returns</div>
                </div>

                <div>
                  <AppValue :value="allocation.perf.roe" before="$" />
                  <div>Total profits</div>
                </div>
              </div>

              <AllocationChart :points="allocation.chart" />
            </div>
          </div>
        </div>
      </section> -->

      <!-- <pre>{{ allocation }}</pre> -->

      <!-- FUTURES -->

      <div v-if="allocation.type === 'futures'">
        <!-- Position -->

        <section class="AllocationDetailsView__position mb-3">
          <div class="card">
            <div class="card-header d-flex">
              <h4>Open positions</h4>

              <button class="btn btn-sm btn-outline-primary ms-auto" v-if="hasOpenPosition()" @click="exportToCSV('POSITION', position)">
                Export to CSV
              </button>
            </div>

            <div class="card-body" v-if="!hasOpenPosition()">
              No open position yet…
            </div>

            <div class="card-body" v-if="hasOpenPosition()">
              <div class="table-responsive">
                <table class="AllocationDetailsView__table AllocationDetailsView__position__table table">
                  <colgroup>
                    <col width="125px">
                    <col>
                    <col width="100px">
                    <col width="100px">
                    <col width="75px">
                    <col width="120px">
                    <col width="120px">
                    <col width="100px">
                    <col width="125px">
                  </colgroup>
                  <thead>
                    <tr>
                      <th>Pair</th>
                      <th>Exchange</th>
                      <th>Status</th>
                      <th>Side</th>
                      <th class="text-center">Leverage</th>
                      <th class="text-end">Entry price</th>
                      <th class="text-end">Current price</th>
                      <th class="text-end">PNL</th>
                      <th class="text-end">Started at</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="item, i in allocation.positions" :key="i">
                      <td>
                        <ExchangePair :pair="item.detail.symbol" />
                      </td>
                      <td>
                        <ExchangeName :exchange="allocation.exchange" />
                      </td>
                      <td>
                        Opened
                      </td>
                      <td>
                        <AllocationPosition :position="item.side" />
                      </td>
                      <th class="text-center">
                        x{{ item.detail.leverage }}
                      </th>
                      <td class="text-end">
                        <AppValue :value="item.detail.entry" before="$" :colored="false" />
                      </td>
                      <td class="text-end">
                        <AppValue :value="item.detail.current" before="$" :colored="false" />
                      </td>
                      <td class="text-end">
                        <AppValue :value="item.detail.pnl" after="%" />
                        <br>
                        <small>
                          ${{ formatDecimal(item.detail.roe) }}
                        </small>
                      </td>
                      <td class="text-end">
                        {{ formatDate(item.when, 'dd/MM/yy') }} UTC
                        <br>
                        {{ formatDate(item.when, 'HH:ii:ss') }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </section>

        <!-- History -->

        <section class="AllocationDetailsView__history mb-3">
          <div class="card">
            <div class="card-header d-flex">
              <h4>Trade history</h4>

              <button class="btn btn-sm btn-outline-primary ms-auto" v-if="hasHistory()" @click="exportToCSV('HISTORY', histories)">
                Export to CSV
              </button>
            </div>

            <div class="card-body" v-if="!hasHistory()">
              No history yet…
            </div>

            <div class="card-body" v-if="hasHistory()">
              <div class="table-responsive">
                <table class="AllocationDetailsView__table AllocationDetailsView__history__table table">
                  <colgroup>
                    <col width="125px">
                    <col>
                    <col width="100px">
                    <col width="100px">
                    <col width="75px">
                    <col width="120px">
                    <col width="120px">
                    <col width="100px">
                    <col width="125px">
                  </colgroup>
                  <thead>
                    <tr>
                      <th>Pair</th>
                      <th>Exchange</th>
                      <th>Status</th>
                      <th>Side</th>
                      <th class="text-center">Leverage</th>
                      <th class="text-end">Entry price</th>
                      <th class="text-end">Closed price</th>
                      <th class="text-end">PNL</th>
                      <th class="text-end">Closed at</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="history in histories" :key="history.id">
                      <td>
                        <ExchangePair :pair="history.symbol" />
                      </td>
                      <td>
                        <ExchangeName :exchange="history.exchange" />
                      </td>
                      <td>
                        Closed
                      </td>
                      <td>
                        <AllocationPosition :position="history.side" />
                      </td>
                      <th class="text-center">
                        x{{ history.leverage }}
                      </th>
                      <td class="text-end">
                        <AppValue :value="history.entry" before="$" :colored="false" />
                      </td>
                      <td class="text-end">
                        <AppValue :value="history.closed" before="$" :colored="false" />
                      </td>
                      <td class="text-end">
                        <AppValue :value="history.pnl" after="%" />
                        <br>
                        <small>
                          ${{ formatDecimal(history.roe) }}
                        </small>
                      </td>
                      <td class="text-end">
                        {{ formatDate(history.closed_at, 'dd/MM/yy') }} UTC
                        <br>
                        {{ formatDate(history.closed_at, 'HH:ii:ss') }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </section>
      </div>

      <!-- SPOT -->

      <div v-if="allocation.type === 'spot'">
        <section class="AllocationDetailsView__position mb-3">
          <div class="card">
            <div class="card-header">
              <h4>Repartition</h4>
            </div>

            <div class="card-body" v-if="!hasAssets()">
              No repartition yet…
            </div>

            <div class="card-body" v-if="hasAssets()">
              <div class="table-responsive">
                <table class="AllocationDetailsView__table AllocationDetailsView__wallet__table table">
                  <colgroup>
                    <col style="width: 150px">
                    <col style="width: 150px">
                    <col style="width: 150px">
                    <col style="width: 150px">
                    <col>
                  </colgroup>
                  <thead>
                    <tr>
                      <th>Asset</th>
                      <th class="text-end">Quantity</th>
                      <th class="text-end">USDT</th>
                      <th />
                      <th />
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(asset, index) of assets" :key="index">
                      <td>
                        <ExchangePair :pair="asset.asset" />
                      </td>

                      <td class="text-end">
                        {{ formatDecimal(asset.qt) }}
                      </td>

                      <th class="text-end">
                        <AppValue :value="asset.USDT" before="$" :colored="false" />
                      </th>

                      <td class="text-end">
                        {{ formatDecimal(asset.percent) }}%
                      </td>

                      <td>
                        <div class="progress" :title="formatDecimal(asset.percent) + '%'">
                          <div class="progress-bar bg-success" :style="{ width: asset.percent + '%' }" />
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </main>
</template>

<script>
  // import AllocationChart from '@/components/AllocationChart'
  import AllocationPosition from '@/components/AllocationPosition'
  import AppValue from '@/components/AppValue'
  import ExchangeName from '@/components/ExchangeName'
  import ExchangePair from '@/components/ExchangePair'
  import ModalAllocationForm from '@/components/ModalAllocationForm'
  // import ModalConfirm from '@/components/ModalConfirm'

  import allocationService from '@/services/allocations.service'

  import useModal from '@/plugins/modal/useModal'
  import { formatRelativeDate, formatDate } from '@/utils/date'
  import { formatDecimal } from '@/utils/number'
  import { exportCSVFile } from '@/utils/csv'
  import { mapState, mapGetters } from 'vuex'

  export default {
    components: {
      // AllocationChart,
      AllocationPosition,
      AppValue,
      ExchangeName,
      ExchangePair
    },

    setup() {
      return {
        modal$: useModal()
      }
    },

    data() {
      return {
        allocation: null,
        position: null,
        histories: null,
        assets: null
      }
    },

    computed: {
       ...mapState('auth', [
        'authenticated'
      ]),

      ...mapGetters('auth', [
        'isAuthAdmin',
        'isAuthAgent',
        'isAuthImpersonate'
      ])
    },

    watch: {
      '$route.params.id': {
        handler(id) {
          if (id) {
            this.load(id)
          }
        },
        immediate: true
      }
    },

    methods: {
      formatDate,
      formatRelativeDate,
      formatDecimal,

      async load(id) {
        this.allocation = null
        this.histories = null
        this.position = null
        this.assets = null

        this.allocation = await allocationService.get(id)

        if (this.allocation.type === 'futures') {
          this.position = this.allocation.position_status
          this.histories = await allocationService.fetchHistory(id)
        }

        if (this.allocation.type === 'spot') {
          this.assets = this.prepareAssets(this.allocation.wallet_composition.assets)
        }
      },

      prepareAssets(assets) {
        const total = assets.reduce((sum, asset) => sum + asset.USDT, 0)

        assets.sort((a, b) => b.USDT - a.USDT)
        assets.forEach((asset) => {
          asset.percent = asset.USDT / total * 100
        })

        return assets
      },

      openForm(allocation) {
        this.modal$
          .open(ModalAllocationForm, {
            closeOnBackdrop: false,
            scrollable: true,
            props: {
              allocation
            }
          })
          .onClose((response) => {
            if (response === 'DELETED') {
              this.$router.push({ name: 'allocations' })
            }
            if (response === 'SAVED') {
              this.load(allocation.id)
            }
          })
      },

      exportToCSV(type, data) {
        if (type === 'POSITION') {
          data = []
          this.allocation.positions.forEach((p) => {
            data.push({
              side: p.side,
              symbol: p.detail.symbol,
              leverage: p.detail.leverage,
              exchange: this.allocation.exchange,
              entry: p.detail.entry,
              open_at: p.when,
              qty: p.detail.size,
              cost: p.detail.cost,
              pnl: p.detail.pnl,
              roe: p.detail.roe
            })
          })
          exportCSVFile(data, this.allocation.name + ' — Open Positions')
        }
        if (type === 'HISTORY') {
          exportCSVFile(data, this.allocation.name + ' — History')
        }
      },

      hasOpenPosition() {
        return this.allocation.positions?.length
      },

      hasHistory() {
        return this.histories && this.histories.length
      },

      hasAssets() {
        return this.assets && this.assets.length
      }
    }
  }
</script>

<style lang="sass" scoped>
  @import '@/sass/styles.vars.all.sass'

  .AllocationDetailsView
    table
      th, td
        vertical-align: middle

  .AllocationDetailsView__meta
    .card
      height: 100%

    label
      display: block
      text-transform: uppercase
      font-size: .75rem
      color: $gray-600
      margin-bottom: $spacer-2

  .AllocationDetailsView__meta__value
    display: flex
    align-items: center
    min-height: 2em
    font-size: 1.2em
    font-weight: 600

  .AllocationDetailsView__meta__profit
    .AllocationDetailsView__meta__value
      font-size: 1.8em
      margin: -.25em 0
      font-weight: normal

  .AllocationDetailsView__chart__values
    display: flex
    align-items: flex-end
    gap: $spacer-5

    div
      margin-bottom: $spacer

    div div
      color: $gray-600
      font-size: .8em

    div:first-child
      :deep(.AppValue)
        font-size: 1.8em

    div:last-child
      :deep(.AppValue)
        font-size: 1.5em

  .AllocationDetailsView__position
    .card-header
      display: flex
      justify-content: space-between

  .AllocationDetailsView__table
    font-size: .9em

    tr
      vertical-align: middle

    th, td
      white-space: nowrap

    :deep(.AllocationPosition)
      font-size: 12px

  .AllocationDetailsView__wallet__table
    :deep(.ExchangePair) .ExchangePair__source
      min-width: 100px

  .AllocationDetailsView__history,
  .AllocationDetailsView__position
    .btn-sm
      font-size: 10px
</style>

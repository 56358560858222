
function convertArrayToCSV(arr) {
  arr = JSON.parse(arr)
  arr = prepareData(arr)

  if (!Array.isArray(arr)) {
    arr = [ arr ]
  }

  const array = [ Object.keys(arr[0]) ].concat(arr)

  return array.map((row) => {
    return Object.values(row).map((value) => {
        return typeof value === 'string' ? JSON.stringify(value) : value
    }).toString()
  }).join('\n')
}

export function exportCSVFile(items, filename) {
  const jsonObject = JSON.stringify(items)
  const csv = convertArrayToCSV(jsonObject)
  const exportedFilenmae = filename + '.csv' || 'export.csv'
  const blob = new Blob([ csv ], {
    type: 'text/csv;charset=utf-8;'
  })

  if (navigator.msSaveBlob) {
    navigator.msSaveBlob(blob, exportedFilenmae)
  } else {
    const link = document.createElement('a')
    const url = URL.createObjectURL(blob)

    link.setAttribute('href', url)
    link.setAttribute('download', exportedFilenmae)
    link.style.visibility = 'hidden'
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }
}

export function prepareData(arr) {
  //console.log(arr, typeof arr)

  arr = arr.map((obj) => {
    Object.keys(obj).forEach((key) => {
      if (key === 'when' || key.includes('_at')) {
        obj[key] = new Date(obj[key]).toISOString()
      }

      if (typeof obj[key] === 'number') {
        obj[key] = obj[key].toFixed(2)
      }
    })

    return obj
  })

  return arr
}

<template>
  <div class="ExchangePair" v-if="target">
    <div class="ExchangePair__source">{{ source }}</div>
    →
    <div class="ExchangePair__target">{{ target }}</div>
  </div>

  <div class="ExchangePair" v-if="!target">
    <div class="ExchangePair__source">{{ source }}</div>
  </div>
</template>

<script>
  export default {
    props: {
      pair: {
        type: String,
        default: ''
      }
    },

    data() {
      return {
        source: this.pair.split('-')[0],
        target: this.pair.split('-')[1]
      }
    }
  }
</script>

<style lang="sass" scoped>
  @import '@/sass/styles.vars.all.sass'

  .ExchangePair
    display: flex
    gap: .1em
    align-items: center
    font-size: .8rem
    text-transform: uppercase

    div
      padding: .5em 1em
      border-radius: .5em
      text-align: center
      min-width: 2em
      background: tint-color($secondary, 90)
      color: white

    .ExchangePair__source
      background: tint-color($secondary, 90)
      color: $secondary

    .ExchangePair__target
      background: tint-color($tertiary, 85)
      color: shade-color($tertiary, 15)

</style>s
